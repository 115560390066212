// LANDING PAGE

// hero section
import HeroSection from '../../widgets/hero-section'

// cards we buy
import CardsWeBuy from '../../widgets/cards-we-buy'

// testimonial section
import Testimonials from '../../widgets/testimonials'

// do more
import DoMore from '../../widgets/do-more'

// why trade with us
import WhyTradeUs from '../../widgets/why-trade-with-us'

// join us
import JoinUs from '../../widgets/join-us'

// ask section
import AskSection from '../../widgets/ask-anything'

// trusted vendor
import TrustedVendor from '../../widgets/trusted-vendor'

// sell unwanted
import SellUnwantedCards from '../../widgets/sell-unwanted'

// need hand
import NeedHand from '../../widgets/need-a-hand'

// contact
import Contact from '../../widgets/contact'

// footer
import Footer from '../../widgets/footer'

import contactBg from '../../assets/images/backgrounds/contact-bg-mobile.png'

import styles from './index.module.css'

const Landing = () => {
  return (
    <main className="kc-landing ">
      <HeroSection />
      <CardsWeBuy />
      {/*Stripes */}
      <div>
        <div className={`${styles.coral_strip_1} py-1`}></div>
        <div className={`${styles.coral_strip_2} py-0.5`}></div>
      </div>
      <Testimonials />
      <DoMore />
      <WhyTradeUs />
      <div className="md:flex md:flex-row max-w-7xl mx-auto mt-10">
        <div className="md:w-1/2">
          <JoinUs />
        </div>
        <div className="md:w-1/2 md:-mt-20" id="faq">
          <AskSection />
        </div>
      </div>
      <TrustedVendor />
      <SellUnwantedCards />
      <div
        className="bg-center bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${contactBg})` }}
      >
        <div className="md:flex md:flex-row max-w-7xl mx-auto mt-10">
          <div className="md:w-1/2">
            <NeedHand />
          </div>
          <div className="md:w-1/2" id="contact">
            <Contact />
          </div>
        </div>
      </div>
      <div className="bg-[#111111]">
        <Footer />
      </div>
    </main>
  )
}

export default Landing

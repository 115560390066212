// CARDS WE BUY

// css
import styles from './index.module.css'

// images
import rectangleMobile from '../../assets/images/backgrounds/rectangle-mobile.png'
import rectangleDesktop from '../../assets/images/backgrounds/rectangle-desktop.png'

import giftCardIcon from '../../assets/images/icons/giftcard.png'
import giftCardShowcaseMobile from '../../assets/images/graphics/cards-mobile.png'
import giftCardShowcaseDesktop from '../../assets/images/graphics/cards-desktop.png'

import checkmark from '../../assets/images/icons/checkmark.png'
import useWindowResize from '../../hooks/useWindowResize.ts'

const CardsWeBuy = () => {
  const { width } = useWindowResize()
  let rectangle
  let giftCardShowcase

  if (width > 760) {
    rectangle = rectangleDesktop
    giftCardShowcase = giftCardShowcaseDesktop
  } else {
    rectangle = rectangleMobile
    giftCardShowcase = giftCardShowcaseMobile
  }


  return (
    <div className="relative">
      <div
        style={{ backgroundImage: `url(${rectangle})` }}
        className={`absolute -z-10 w-full h-full md:h-[125vh] top-0 md:-top-[30rem] xl:h-[150vh] xl:-top-[30rem] bg-cover bg-center bg-no-repeat `}
      ></div>
      <section className="max-w-7xl mx-auto">
        <div className="mt-8 py-5">
          <div className="ml-7">
            <img src={giftCardIcon} alt="gift icon" />
          </div>

          <div className="flex flex-row justify-between pl-7 space-x-4">
            <div className="basis-10/12 md:basis-6/12">
              <p className="heading-1 text-left mt-6">Gift Cards We Buy</p>
              <p className="small-text text-left wide-line-height mt-6">
                We buy a whole lot of gift cards from the USA and many other
                countries. Our speed and quality of service cannot be matched by
                any other. Why not testify to it by giving us a try? Hit the
                signup button and trade your digital assets now.
              </p>

              <div className="mt-4">
                <ul className="">
                  <li className="flex flex-row gap-3 my-3">
                    <span className={`${styles.list_item_circle} p-1.5`}>
                      <img src={checkmark} alt="checkmark" />
                    </span>

                    <p className="grid content-center small-text text-sm">
                      iTunes, Steam, Google, Visa
                    </p>
                  </li>

                  <li className="flex flex-row gap-3 my-3">
                    <span className={`${styles.list_item_circle} p-1.5`}>
                      <img src={checkmark} alt="checkmark" />
                    </span>

                    <p className="grid content-center small-text text-sm">
                      Walmart, Nike, Amex, Offgamers
                    </p>
                  </li>

                  <li className="flex flex-row gap-3 my-3">
                    <span className={`${styles.list_item_circle} p-1.5`}>
                      <img src={checkmark} alt="checkmark" />
                    </span>

                    <p className="grid content-center small-text text-sm">
                      Amazon, Sephora, Nordstrom, Nike
                    </p>
                  </li>

                  <li className="flex flex-row gap-3 my-3">
                    <span className={`${styles.list_item_circle} p-1.5`}>
                      <img src={checkmark} alt="checkmark" />
                    </span>

                    <p className="grid content-center small-text text-sm">
                      Apple Store, Macy, Vanilla, Target
                    </p>
                  </li>

                  <li className="flex flex-row gap-3 my-3">
                    <span className={`${styles.list_item_circle} p-1.5`}>
                      <img src={checkmark} alt="checkmark" />
                    </span>

                    <p className="grid content-center small-text text-sm">
                      eBay, Best-Buy, Home Depot
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="basis-2/12 md:basis-6/12 mt-7">
              <img
                src={giftCardShowcase}
                alt="gift card showcase"
                className="w-[22vw] md:w-[60vw]"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CardsWeBuy

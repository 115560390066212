// NAV MENU

// css
import styles from './index.module.css'

// image
import logo from '../../assets/images/logo/logo.png'
import { BLOG_URL, REGISTER_URL, SIGNIN_URL } from '../../utils/constants'

const NavMenu = (props) => {
  const { close } = props

  return (
    <nav className={`${styles.mobile_nav} kc-app-nav`}>
      <div className="max-w-5xl py-3.5 px-3">
        <section className={`  flex flex-row justify-between items-center p-3`}>
          <img src={logo} alt="king cards logo" />
          <button className="p-3 rounded-lg" onClick={() => close()}>
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </section>

        <ul className="py-3.5 px-3">
          <li>
            <a
              className="flex body-text py-2 my-1"
              href="#about"
              onClick={() => close()}
            >
              About
            </a>
          </li>
          <li>
            <a
              className="flex body-text py-2 my-1"
              href="#contact"
              onClick={() => close()}
            >
              Contact
            </a>
          </li>
          <li>
            <a
              className="flex body-text py-2 my-1"
              href={BLOG_URL}
              target="_blank"
              rel="noreferrer"
              onClick={() => close()}
            >
              Blog
            </a>
          </li>
          <li>
            <a
              className="flex body-text py-2 my-1"
              href="#faq"
              onClick={() => close()}
            >
              FAQs
            </a>
          </li>
        </ul>

        <div className="flex flex-col mt-6 mx-7">
          <a
            className={`${styles.btn} my-2 py-4 small-text light-blue`}
            target="_blank"
            rel="noreferrer"
            href={SIGNIN_URL}
          >
            <button>Sign in</button>
          </a>
          <a
            className={`${styles.btn} my-2 py-4 small-text white--text blue`}
            target="_blank"
            rel="noreferrer"
            href={REGISTER_URL}
            to="/register"
          >
            <button>Register</button>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default NavMenu

// FOOTER

// css

// images
import fbIcon from '../../assets/images/icons/facebook.png'
import instaIcon from '../../assets/images/icons/instagram.png'
import whatsappIconWhite from '../../assets/images/icons/whatsapp.png'
import {
  HOW_TO_TRADE,
  PRIVACY_POLICY_URL,
  REGISTER_URL,
  SIGNIN_URL,
  TERMS_OF_USE_URL,
} from '../../utils/constants'

const Footer = () => {
  return (
    <footer className="px-7 py-12 mx-auto max-w-7xl">
      <div className="flex flex-wrap">
        <div className="py-6 px-2 md:w-1/2">
          <p className="heading-1 text-left white--text mb-6">About Us</p>
          <p className="small-text text-left white--text">
            Sell iTunes, Amazon, eBay, Google Play and other Gift Cards
            instantly for NAIRA.
            <br />
            KingCards NG is an exchange platform where you can convert your Gift
            Cards to Naira easily. We make exchanging your gift cards very easy.
          </p>
        </div>
        <div className="flex flex-row flex-grow md:1/2 justify-between gap-12 py-6 px-2 mb-12">
          <div className="basis-1/2">
            <p className="heading-1 text-left white--text mb-6">Quick Links</p>
            <ul>
              <li className="flex py-1">
                <a className="small-text text-left white--text" href="#contact">
                  Contact
                </a>
              </li>
              <li className="flex py-1">
                <a
                  href={HOW_TO_TRADE}
                  target="_blank"
                  rel="noreferrer"
                  className="small-text text-left white--text"
                >
                  How to trade?
                </a>
              </li>
              <li className="flex py-1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={REGISTER_URL}
                  className="small-text text-left white--text"
                  to="/rates"
                >
                  Register
                </a>
              </li>
              <li className="flex py-1">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={SIGNIN_URL}
                  className="small-text text-left white--text"
                  to="/login"
                >
                  Login
                </a>
              </li>
            </ul>
          </div>

          <div className="basis-1/2">
            <p className="heading-1 text-left white--text mb-6">Company</p>
            <ul>
              <li className="flex py-1">
                <a className="small-text text-left white--text" href="#about">
                  About
                </a>
              </li>
              <li className="flex py-1">
                <a
                  className="small-text text-left white--text"
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="flex py-1">
                <a
                  className="small-text text-left white--text"
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </li>
              <li className="flex py-1">
                <a className="small-text text-left white--text" href="#faq">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <hr className="mb-6"></hr>
        <div className="flex flex-wrap">
          <p className="w-full small-text text-left white--text md:w-1/2">
            Kingcards &copy; 2022. All rights reserved.
          </p>
          <ul className="flex flex-row mt-6 md:mt-0 md:justify-end md:w-1/2">
            <li className="pr-1">
              <a
                href="https://www.facebook.com/kingcardsng1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fbIcon}
                  alt="icon"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
            </li>
            <li className="px-1">
              <a
                href="https://instagram.com/kingcards.ng"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instaIcon}
                  alt="icon"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
            </li>
            <li className="pl-1">
              <a
                href="https://api.whatsapp.com/send?phone=2349082896000&text=Hi,%20I%20want%20to%20sell%20card"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={whatsappIconWhite}
                  alt="icon"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer

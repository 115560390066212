import routes from './routes.js';

const ROUTES = routes;

export {
  ROUTES
}

export const SIGNIN_URL = "http://mobile.kingcards.ng/auth/signin"
export const REGISTER_URL = "http://mobile.kingcards.ng/auth/register"
export const GOOGLE_PLAY_URL ="https://play.google.com/store/apps/details?id=com.kingcards.kingcards"
export const APP_STORE_URL = "https://apps.apple.com/ng/app/kingscard-sell-gift-cards/id1570500302"
export const BLOG_URL = "http://kingcards.ng/read"
export const TERMS_OF_USE_URL = "http://kingcards.ng/read/terms-of-use"
export const PRIVACY_POLICY_URL = "http://kingcards.ng/read/privacy-policy"
export const LEARN_MORE_URL = "http://kingcards.ng/read/about-kingcards"
export const HOW_TO_TRADE = "http://kingcards.ng/read/how-to-trade"
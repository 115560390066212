// HEADER

// internal state
import { useState } from 'react'

// css
import styles from './index.module.css'

// image
import logo from '../../assets/images/logo/logo.png'

// nav
import NavMenu from '../nav-menu'

import { REGISTER_URL, SIGNIN_URL, BLOG_URL } from '../../utils/constants'

const Header = () => {
  const [open, setOpen] = useState(false)

  const closeMenu = () => setOpen(false)
  const openMenu = () => setOpen(true)

  return (
    <header className={`w-full fixed flex-none py-3.5 px-3`}>
      <div className="max-w-7xl">
        <section
          className={`${styles.mobile_header_content} flex flex-col md:flex-row md:items-center p-3`}
        >
          <div className="flex flex-row items-center justify-between">
            <img src={logo} alt="king cards logo" />

            <button
              className="p-3 rounded-lg md:hidden cursor-pointer"
              onClick={() => openMenu()}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                {!open && (
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                )}
                {open && (
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                )}
              </svg>
            </button>
          </div>

          {!open && (
            <div className="hidden md:flex md:flex-1 items-center">
              <ul className="inline-flex justify-center flex-1 space-x-7">
                <li>
                  <a href="#about" className="flex body-text py-2 my-1">
                    About
                  </a>
                </li>
                <li>
                  <a className="flex body-text py-2 my-1" href="#contact">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="flex body-text py-2 my-1"
                    href={BLOG_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a className="flex body-text py-2 my-1" href="#faq">
                    FAQs
                  </a>
                </li>
              </ul>

              <div className="inline-flex justify-center items-center space-x-4 ">
                <a
                  className="rounded-lg px-8 py-0 h-11 small-text light-blue flex justify-center"
                  rel="noreferrer"
                  target="_blank"
                  href={SIGNIN_URL}
                  to="/login"
                >
                  <button >Sign in</button>
                </a>
                <a
                  className={`rounded-lg px-8 py-0 h-11 small-text white--text blue flex justify-center`}
                  rel="noreferrer" target="_blank" href={REGISTER_URL}
                >
                  <button>
                    Register
                  </button>
                </a>
              </div>
            </div>
          )}
        </section>
        {open && <NavMenu close={closeMenu} />}
      </div>
    </header>
  )
}

export default Header

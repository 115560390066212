// HERO SECTION

// header
import Header from '../header'

// css
import styles from './index.module.css'

// images

import appleAppstoreLogo from '../../assets/images/icons/appstore.png'
import googlePlaystoreLogo from '../../assets/images/icons/playstore.png'
import phoneImage from '../../assets/images/graphics/phone.png'

import mobileBg from '../../assets/images/backgrounds/hero-bg-mobile.png'
import desktopBg from '../../assets/images/backgrounds/hero-bg-desktop.png'

import useWindowResize from '../../hooks/useWindowResize.ts'
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../utils/constants'

const HeroSection = () => {
  const { width } = useWindowResize()
  let background

  if (width > 760) {
    background = desktopBg
  } else {
    background = mobileBg
  }
  return (
    <div className="relative z-50">
      <div
        style={{ backgroundImage: `url(${background})` }}
        className={`absolute -z-10 w-full top-0 h-[85%] md:h-[90%] lg:h-[90%] xl:h-[110%] xl:-top-32 bg-cover bg-center bg-no-repeat `}
      ></div>
      <div className={`max-w-7xl mx-auto`}>
        <div className={` `}>{/* Heee */}</div>

        <div className={`relative`}>
          <Header />
          <div className="pt-32 lg:flex ">
            <div className={`lg:w-1/2 lg:pt-10 mb-5 lg:text-left `}>
              <p className="hero-text mx-11 ">
                The best and most trusted website to trade your gift cards for
                instant cash.
              </p>

              <p className="body-text mx-11 mt-5">
                Join thousands who have already discovered the smarter way to
                sell gift cards. We'll make sure you get the best price when you
                sell your gift cards to us.
              </p>

              <div className="grid grid-cols-2 gap-2 mx-11 mt-5 mb-10 max-w-xs">
                <a target="_blank" rel="noreferrer" href={GOOGLE_PLAY_URL} className={`${styles.app_button} flex flex-row`}>
                  <div
                    className="grid justify-center content-center basis-1/4"
                    style={{ height: '100%' }}
                  >
                    <img
                      src={googlePlaystoreLogo}
                      alt="google playstore logo"
                    />
                  </div>
                  <div
                    className="grid content-center basis-3/4"
                    style={{ height: '100%' }}
                  >
                    <p className="x-small-text text-left white--text">
                      Get it on
                    </p>
                    <p className="bold-text text-left white--text">
                      Google Play
                    </p>
                  </div>
                </a>

                <a target="_blank" rel="noreferrer" href={APP_STORE_URL} className={`${styles.app_button} flex flex-row`}>
                  <div
                    className="grid justify-center content-center basis-1/4"
                    style={{ height: '100%' }}
                  >
                    <img src={appleAppstoreLogo} alt="google playstore logo" />
                  </div>
                  <div
                    className="grid content-center basis-3/4"
                    style={{ height: '100%' }}
                  >
                    <p className="x-small-text text-left white--text">
                      Download on
                    </p>
                    <p className="bold-text text-left white--text">App Store</p>
                  </div>
                </a>
              </div>
            </div>

            <div
              className={`${styles.phone_image} mx-7  lg:w-1/2 lg:pt-[120px] lg:h-[550px]`}
              style={{ backgroundImage: `url(${phoneImage})` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection

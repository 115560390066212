// WHY TRADE WITH US

// css
import styles from './index.module.css'

// images
import whySectionBg from '../../assets/images/backgrounds/why-section-mobile.png'
import questionMark from '../../assets/images/icons/question-mark.png'
import incredibleXP from '../../assets/images/icons/incredible-experience.png'
import customerReviews from '../../assets/images/icons/customer-reviews.png'
import instantPayment from '../../assets/images/icons/instant-payment.png'
import { REGISTER_URL } from '../../utils/constants'

const WhyTradeUs = () => {
  return (
    <section
      id="about"
      className={`${styles.why_section} xl:pt-24 xl:-mt-60`}
      style={{ backgroundImage: `url(${whySectionBg})` }}
    >
      <div className="mx-auto max-w-7xl xl:pt-40">
        <div className="md:flex lg:space-x-10">
          <div className=" md:w-1/2 flex flex-col justify-start pl-7 pt-10">
            <img
              src={questionMark}
              alt="question mark icon"
              className="mb-10 h-10 w-10"
            />
            <p className="heading-1 text-left">Who is Kingcards?</p>
            <p className="small-text text-left mt-4 mb-12 mr-7">
              Who is Kingcards? Kingcards is currently one of the most trusted
              online trading platforms for gift cards in Nigeria. Not only for
              they accept the more popular iTunes and Amazon gift cards, you can
              also sell Google Play, Steam, Walmart, and other gift cards on
              this platform.
              <br />
              <br />
              <br />
              The one thing one thing you should know about Kingcards is that
              they are direct loaders, not middlemen, so you should not worry.
             
              <br />
            </p>

            <p className="heading-1 text-left">
              Why Trade Your Gift Cards With Us?
            </p>
            <p className="small-text text-left mt-4 mb-12 mr-7">
              We are a registered company that has been in business for 4 years
              and our clean records can attest to that. Integrity and excellent
              customer satisfaction are what we stand for.
              <br />
              <br />
              We can boast of great referrals which is one of the best avenues
              for sustaining our business.
            </p>

            <a
              href={REGISTER_URL}
              target="_blank"
              rel="noreferrer"
              className={`${styles.why_btn}  px-3 py-4 small-text white--text`}
            >
              Get Started
            </a>
          </div>
          <div className=" md:w-1/2 mt-2 pt-12 pb-10 flex flex-col">
            <div
              className={`${styles.why_card} mx-7 mb-6 p-8 lg:inline-flex lg:items-center lg:space-x-10`}
            >
              <img
                src={incredibleXP}
                alt="icon"
                className="mx-auto lg:h-14 lg:w-14"
              />
              <div>
                <p className="heading-2 mb-3 mt-7 lg:text-left">
                  Incredible Experience
                </p>
                <p className="small-text lg:text-left">
                  We deliver the best gift card exchange experience through our
                  services and simple trading process.
                </p>
              </div>
            </div>

            <div
              className={`${styles.why_card} mx-7 mb-6 p-8 lg:inline-flex lg:items-center lg:space-x-10`}
            >
              <img
                src={customerReviews}
                alt="icon"
                className="mx-auto lg:h-14 lg:w-14"
              />
              <div>
                <p className="heading-2 mb-3 mt-7 lg:text-left">
                  Customer Reviews
                </p>
                <p className="small-text lg:text-left">
                  Our 20,000+ customers have been trading Amazon, iTunes, Google
                  Play, Steam and other gift cards with us over the internet.
                </p>
              </div>
            </div>

            <div
              className={`${styles.why_card} mx-7 mb-6 p-8 lg:inline-flex lg:items-center lg:space-x-10`}
            >
              <img
                src={instantPayment}
                alt="icon"
                className="mx-auto lg:h-14 lg:w-14"
              />
              <div>
                <p className="heading-2 mb-3 mt-7 lg:text-left">
                  Instant Payments
                </p>
                <p className="small-text lg:text-left">
                  Our payment facility is among the best available, you can get
                  cash in exchange for your gift cards in few minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyTradeUs

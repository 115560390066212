// TRUSTED VENDOR

// css
import styles from './index.module.css'

// image
import trustedVendorBg from '../../assets/images/backgrounds/trusted-vendor-bg.png'
import info_nigeria from '../../assets/images/logo/info-nigeria.png'
import nairarbarter from '../../assets/images/logo/nairabarter.png'
import yabaleft from '../../assets/images/logo/yabaleft.png'

const TrustedVendor = () => {
  return (
    <div className="mx-auto max-w-7xl ">
      <section
        className={`${styles.trusted_vendor} py-12 px-7 xl:py-24 xl:relative xl:top-20 xl:rounded-xl`}
        style={{ backgroundImage: `url(${trustedVendorBg})` }}
      >
        <div className="px-7">
          <p className="heading-1 mb-4">Trusted Gift Card Vendor</p>
          <p className="small-text mb-10 md:mx-[30%]">
            As a demonstration of our quality service in trading giftcards, and
            digital assets we have been published by some platforms across
            Nigeria and beyond.
          </p>
        </div>
        <div className="flex flex-wrap space-y-5 justify-center space-x-4 items-baseline">
          <a
            href="https://www.informationng.com/2018/07/sell-gift-cards-in-nigeria-at-best-rates.html/amp"
            target="_blank"
            rel="noreferrer"
          >
            <img className="max-h-16" src={info_nigeria} alt="info nigeria" />
          </a>
          <a
            href="https://nairabarter.com/blog/buy-and-sell-gift-cards-in-nigeria/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="max-h-12" src={nairarbarter} alt="nairabarter" />
          </a>
          <a href="https://www.yabaleftonline.ng/sell-gift-cards-in-nigeria-rates/" target="_blank" rel="noreferrer">
            <img className="max-h-12" src={yabaleft} alt="yabaleft" />
          </a>
        </div>
      </section>
    </div>
  )
}

export default TrustedVendor

// TESTIMONIALS

// css
import styles from './index.module.css';

// images
import testimonialBg from '../../assets/images/backgrounds/testimonial-bg-mobile.png';
import person1 from '../../assets/images/graphics/person1.png';
import person2 from '../../assets/images/graphics/person2.png'
import person3 from '../../assets/images/graphics/person3.png'
import twitterIcon from '../../assets/images/icons/twitter.png';


const Testimonials = () => {
    return (
      <section className={`${styles.testimonial_section} py-12 xl:pb-80 xl:-mb-72`} style={{backgroundImage: `url(${testimonialBg})`}}>
        <div className="max-w-7xl mx-auto">
        <p className='heading-1 text-left ml-7'>Testimonials</p>

        <div className={`${styles.sliding_section} no-scrollbar`}>
          <div className={`${styles.carousel_container} flex mx-auto items-center justify-start mt-6 pl-7`}>
            <div className={`${styles.carousel_content} h-full w-[300px] mr-4 px-6 pt-7`}>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row'>
                  <div className={`${styles.profile_pic} basis-1/4`} style={{backgroundImage: `url(${person1})`}}></div>
                  <div className='ml-2'>
                    <p className='heading-3 text-left'>Olamide Joshua</p>
                    <p className='small-text text-left grey--text'>Verified Customer</p>
                  </div>
                </div>
                <div className=''>
                  <img src={twitterIcon} alt='twitter logo' style={{width:'15px'}} />
                </div>
              </div>
              <div className='flex flex-row mt-7'>
                <p className='small-text'>
                Kings card always delivers at the best value, i have traded gift cards worth over $3,000 and they have been excellent.
                </p>
              </div>
            </div>

            <div className={`${styles.carousel_content} h-full w-[300px] mr-4 px-6 pt-7`}>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row'>
                  <div className={`${styles.profile_pic} basis-1/4`} style={{backgroundImage: `url(${person2})`}}></div>
                  <div className='ml-2'>
                    <p className='heading-3 text-left'>Martins Obi</p>
                    <p className='small-text text-left grey--text'>Verified Customer</p>
                  </div>
                </div>
                <div className=''>
                  <img src={twitterIcon} alt='twitter logo' style={{width:'15px'}} />
                </div>
              </div>
              <div className='flex flex-row mt-7'>
                <p className='small-text'>
                When it comes to quick and Safe trades, i always use king cards
                </p>
              </div>
            </div>

            <div className={`${styles.carousel_content} h-full w-[300px] mr-4 px-6 pt-7`}>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row'>
                  <div className={`${styles.profile_pic} basis-1/4`} style={{backgroundImage: `url(${person3})`}}></div>
                  <div className='ml-2'>
                    <p className='heading-3 text-left'>Prince J Val</p>
                    <p className='small-text text-left grey--text'>Verified Customer</p>
                  </div>
                </div>
                <div className=''>
                  <img src={twitterIcon} alt='twitter logo' style={{width:'15px'}} />
                </div>
              </div>
              <div className='flex flex-row mt-7'>
                <p className='small-text'>
                Received great customer service in trading my gift cards with them. I would totally recommend to anyone who wants great trading experience.</p>
              </div>
            </div>
          </div>
        </div>

        </div>
      </section>
    )
}

export default Testimonials;
